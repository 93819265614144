import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeConsumer } from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import get from 'lodash/get';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { Header2025, Link, Icon } from '@comicrelief/component-library';
import { breakpointValues } from '@comicrelief/component-library/src/theme/shared/allBreakpoints';
import searchIcon from '@comicrelief/component-library/src/components/Organisms/Header/assets/icon--search--2023.svg';
import shopIcon from '@comicrelief/component-library/src/components/Organisms/Header/assets/icon--shop--2023.svg';
import payinIcon from '@comicrelief/component-library/src/components/Organisms/Header/assets/PayIn.svg';
import HeaderESU from '../HeaderESU/HeaderESU';

const FixedHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  // To out-do any Shorthand story embeds
  z-index: 1000;
  font-family: ${({ theme }) => theme.fontFamilies(theme.font.regular)};
  font-family: ${({ theme }) => (theme.font.semiBold
    ? theme.fontFamilies(theme.font.semiBold)
    : theme.fontFamilies(theme.font.regular))};

  // Fixed nav ONLY on our 'full nav' breakpoints as it's now too
  // tall to do this on smaller breakpoints:
  @media ${({ theme }) => theme.allBreakpoints('Nav')} {
    position: fixed;
  }
`;

const DonateButtonLink = (
  removeDonateLink,
  landingPageCartId
) => (
  <>
    {!removeDonateLink && (
      <Link
        color="red"
        type="button"
        rel="noopener"
        href={landingPageCartId ? `https://donation.comicrelief.com?cartId=${landingPageCartId}` : 'https://donation.comicrelief.com/s/website'}
        target="blank"
        key="1"
        className="header-donate-button"
      >
        Donate
      </Link>
    )}
  </>
);

const metaIconLinks = options => (
  <>
    {options.showEsu && (
      <HeaderESU />
    )}

    {options.showShop && (
    <div>
      <Icon
        icon={shopIcon}
        title="Shop"
        target="_blank"
        role="button"
        href={options.shopUrl || 'https://shop.comicrelief.com/'}
        brand="comicrelief"
        tabIndex="0"
        isHeader
        id="shop"
      />
    </div>

    )}

    {options.showPayin && (
      <div>
        <Icon
          icon={payinIcon}
          title="Pay in"
          target="_blank"
          role="button"
          href={options.payinUrl || 'https://donation.comicrelief.com/?cartId=rnd24-fundraisingpayin'}
          brand="comicrelief"
          tabIndex="0"
          isHeader
          id="payin"
        />
      </div>
    )}

    {options.showSearch && (
    <div>
      <Icon
        icon={searchIcon}
        title="Search"
        target="self"
        role="button"
        href={options.searchUrl || '/search'}
        brand="comicrelief"
        tabIndex="0"
        isHeader
        id="search"
      />
    </div>
    )}
  </>
);

const Header = ({
  removeDonateLink = false,
  campaign = 'comicrelief',
  landingPageCartId = null
}) => {
  // This will stop the header from hiding when the ESU Modal is opened
  const [showBoxShadow, setShowBoxShadow] = useState(false);

  useScrollPosition(({ currPos }) => {
    // Check the breakpoint before doing anything; handily,
    // useScrollPosition is called on screen-resizing too:
    const currentScreenWidth = typeof window !== 'undefined' ? window.innerWidth : null;
    const desktopNav = currentScreenWidth >= breakpointValues.Nav;
    const notAtTop = currPos.y < 0;

    // Dynamically add/remove header boxshadow:
    setShowBoxShadow(notAtTop && desktopNav);
  },
  [], null, false, 0);

  return (
    <StaticQuery
      query={graphql`
        query HeaderQuery {
          allContentfulAdminConfig {
            edges {
              node {
                title
                control
                url
              }
            }
          }
          contentfulComponentMenu(title: { eq: "Header menu" }) {
            title
            characterLimit
            menuGroups {
              title
              id
              links {
                ... on Node {
                  ... on ContentfulPageArticle {
                    title
                    path
                    internal {
                      type
                    }
                  }
                }
                ... on ContentfulPageLandingPage {
                  title
                  path
                  internal {
                    type
                  }
                }
                ... on ContentfulComponentLink {
                  id
                  title
                  url
                  internal {
                    type
                  }
                  reference {
                    path
                  }
                }
                ... on ContentfulPageDefault {
                  title
                  path
                  internal {
                    type
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const thisMenu = get(data, 'contentfulComponentMenu');
        const adminConfig = get(data, 'allContentfulAdminConfig.edges');

        // Config defaults
        const options = {
          showEsu: false,
          showSearch: true,
          showShop: false,
          showPayin: false,
          payinUrl: null,
          searchUrl: null,
          shopUrl: null
        };

        const config = adminConfig.map(configEl => ({
          title: configEl.node.title,
          control: configEl.node.control,
          url: configEl.node.url
        }));

        for (let i = 0; i < config.length; i += 1) {
          const element = config[i];
          const title = element.title.toLowerCase();

          if (title.includes('esu')) {
            options.showEsu = element.control;
          } else if (title.includes('search')) {
            options.showSearch = element.control;
            options.searchUrl = element.url;
          } else if (title.includes('shop')) {
            options.showShop = element.control;
            options.shopUrl = element.url;
          } else if (title.includes('payin')) {
            options.showPayin = element.control;
            options.payinUrl = element.url;
          }
        }

        if (thisMenu !== null) {
          return (
            <ThemeConsumer>
              {(theme) => {
                const campaignName = () => {
                  if (campaign === 'sportrelief') {
                    return 'Sport Relief Gameon';
                  }
                  if (campaign === 'pride') {
                    return 'Pride';
                  }
                  return 'Comic Relief';
                };

                return (
                  <FixedHeader font={theme.font.regular}>
                    <Header2025
                      showBoxShadow={showBoxShadow}
                      campaign={campaignName()}
                      navItems={thisMenu}
                      characterLimit={thisMenu.characterLimit || 60}
                      donateButton={DonateButtonLink(
                        removeDonateLink,
                        landingPageCartId
                      )}
                      metaIcons={metaIconLinks(options)}
                    />
                  </FixedHeader>
                );
              }}
            </ThemeConsumer>
          );
        }
        return null;
      }}
    />
  );
};

Header.propTypes = {
  removeDonateLink: PropTypes.bool,
  campaign: PropTypes.string,
  landingPageCartId: PropTypes.string
};

export default Header;
