import React from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  ShareButton
} from '@comicrelief/component-library';

import {
  Body,
  Container,
  Header,
  ShareContainer,
  Wrapper
} from './Prize.styles';

import FurtherInfo from './FurtherInfo';

const ThankYou = ({
  title, body = null, pastBody = null, summary = null, termsRef, entryByPost, termsConditions, faQs
}) => (
  <Wrapper>
    <Container>
      <Header>
        <Text tag="h1" family="Anton" size="big" uppercase>
          {title}
        </Text>
        <ShareContainer data-test="social-share">
          <ShareButton />
        </ShareContainer>
      </Header>
      <Body>
        {pastBody && pastBody[0] ? pastBody : (
          <>
            <Text tag="h2" family="Anton" size="xl" uppercase>
              Thanks to everyone who supported &quot;
              {title}
              &quot;
            </Text>
            <Text tag="p" size="l">
              The winner will be contacted shortly.
            </Text>
          </>
        )}
        {summary}
        {body && body[0] ? body : null }
      </Body>
      <FurtherInfo
        termsRef={termsRef}
        entryByPost={entryByPost}
        termsConditions={termsConditions}
        faQs={faQs}
      />
    </Container>
  </Wrapper>
);

ThankYou.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.node,
  pastBody: PropTypes.node,
  summary: PropTypes.node,
  termsRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape()
  ]),
  entryByPost: PropTypes.shape(),
  termsConditions: PropTypes.shape(),
  faQs: PropTypes.shape()
};

export default ThankYou;
