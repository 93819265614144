import React from 'react';
import { graphql } from 'gatsby';
import { get } from 'lodash';
import LayoutNoData from '../../components/Layout/LayoutNoData';
import SEO from '../../components/SEO/SEO';
import Paragraphs from '../../components/Paragraphs/Paragraphs';
import newsDefaultImage from '../../../static/assets/news-default.jpg';
import Wrapper from './Landing.styles';

class Landing extends React.Component {
  render() {
    const page = get(this.props, 'data.allContentfulPageLandingPage.edges[0].node');
    const header = get(this.props, 'data.contentfulComponentMenu');
    const headerConfig = get(this.props, 'data.allContentfulAdminConfig.edges');
    const { edges } = get(this.props, 'data.allContentfulPagePartner');
    const { edges: people } = get(this.props, 'data.allContentfulPagePerson');
    const { edges: grants } = get(this.props, 'data.allContentfulGrant');
    const { edges: moneyBuys } = get(this.props, 'data.allContentfulCart');
    const descData = get(this.props, 'data.allContentfulDescriptor');
    const paragraphs = get(
      this.props,
      'data.allContentfulPageLandingPage.edges[0].node.paragraphs'
    );
    const articles = get(this.props, 'data.featArticles.edges');
    const {
      title, path, summary, imageSeo, removeDonateLink, cartId, excludeFromSearchEngineIndexing
    } = page;
    const seoSummary = summary !== null && typeof summary.summary !== 'undefined' ? summary.summary : '';
    const seoImage = imageSeo ? imageSeo.file.url : newsDefaultImage;
    const { theme, themeCustomisation } = get(this.props, 'pageContext');
    const landingPageCartId = cartId;

    return (
      <LayoutNoData
        theme={theme}
        removeDonateLink={removeDonateLink}
        menu={header}
        adminConfig={headerConfig}
        landingPageCartId={landingPageCartId}
      >
        <SEO title={`${title} | Comic Relief`} description={seoSummary} image={seoImage} pathname={path} excludeFromSearchEngineIndexing={excludeFromSearchEngineIndexing} />
        <Wrapper data-test="landing" pageTheme={themeCustomisation}>
          {paragraphs && (
            <Paragraphs
              theme={theme}
              paragraphs={paragraphs}
              partners={edges}
              people={people}
              articles={articles}
              grantsData={grants}
              moneyBuys={moneyBuys}
              descriptors={descData}
            />
          )}
        </Wrapper>
      </LayoutNoData>
    );
  }
}

export default Landing;

export const pageQuery = graphql`
  query getLanding($slug: String, $category: String, $partnerCategory: [String]) {
    allContentfulAdminConfig {
      edges {
        node {
          title
          control
          url
        }
      }
    }
    contentfulComponentMenu(title: { eq: "Header menu" }) {
      title
      characterLimit
      menuGroups {
        title
        id
        links {
          ... on Node {
            ... on ContentfulPageArticle {
              title
              path
              internal {
                type
              }
            }
          }
          ... on ContentfulPageLandingPage {
            title
            path
            internal {
              type
            }
          }
          ... on ContentfulComponentLink {
            id
            title
            url
            internal {
              type
            }
            reference {
              path
            }
          }
          ... on ContentfulPageDefault {
            title
            path
            internal {
              type
            }
          }
        }
      }
    }
    allContentfulPageLandingPage(filter: { path: { eq: $slug } }) {
      edges {
        node {
          path
          title
          cartId
          excludeFromSearchEngineIndexing
          summary {
            summary
          }
          imageSeo {
            file {
              url
            }
          }
          removeDonateLink
          paragraphs {
            ...PartnerListParagraph
            ...PeopleListParagraph
            ...SingleMessageParagraph
            ...CardsParagraph
            ...DonateParagraph
            ...MembershipParagraph
            ...FeaturedNewsParagraph
            ...IframeParagraph
            ...TeasersParagraph
            ...BoxesParagraph
            ...CardsDsParagraph
            ...JokeGeneratorParagraph
            ...BigRedButtonChallengeParagraph
            ...DescriptorParagraph
            ...SingleMessageDsParagraph
            ...Promo
            ...Heading
            ...BannerParagraph
            ...BitesizeParagraph
            ...VideoBannerParagraph
            ...HTMLParagraph
            ...ImpactSlider
            ...MapParagraph
            ...WhatYourMoneyDoesCarousel
            ...RichtextCarousel
          }
        }
      }
    }

    featArticles: allContentfulPageArticle(
      filter: {
        categories: {
          elemMatch: {
            title: {
              glob: $category
            }
          }
        }
        type: { eq: "News Article" }
        excludeFromFeatureArticles: { ne: "Yes" }
      }
      limit: 3
      sort: { fields: displayDate, order: DESC }
    ) {
      edges {
        node {
          title
          categories {
            title
          }
          path
          type
          id
          displayDate(formatString: "D MMMM YYYY")
          image {
            ...Picture
          }
        }
      }
    }

    allContentfulPagePartner(sort: { fields: weight, order: ASC }, filter: {group: {title: {in: $partnerCategory}}}) {
      edges {
        node {
          ...PartnerList
        }
      }
    }

    allContentfulPagePerson(filter: {type: {ne:null}}) {
      edges {
        node {
          ...PeopleList
        }
      }
    }

    allContentfulDescriptor(sort: {fields: date, order: DESC}) {
      group(field: category) {
        fieldValue
        totalCount
      }
      edges {
        node {
          ...Descriptor
        }
      }
    }

    allContentfulGrant(filter: {theme: {in: ["A safe place to be", "Children survive and thrive", "Gender justice", "Global mental health", "Other"]}, title: {}, tags: {in: "RND19"}}, sort: {fields: [countries], order: ASC}, limit: 12) {
      edges {
        node {
          title
          slug
          theme
          heroImage {
            fluid(cropFocus: CENTER, maxHeight: 600, resizingBehavior: FILL, maxWidth: 800) {
              srcSet
            }
            fallback: fixed {
              src
            }
            placeHolder: fixed(cropFocus: CENTER, height: 600, resizingBehavior: FILL, width: 800, quality: 1) {
              src
            }
            seoImage: resize {
              src
            }
          }
        }
      }
    }

    allContentfulCart {
      edges {
        node {
          cartId
          singleGiving {
            mobileImage {
              ...Picture
            }
            moneybuys {
              description
              value
            }
          }
          regularGiving {
            moneybuys {
              description
              value
            }
          }
        }
      }
    }
  }
`;
