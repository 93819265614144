import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { camelCase, kebabCase } from 'lodash';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';

import {
  Text,
  Checkbox,
  Input,
  ShareButton,
  Picture,
  Link,
  ArticleTeaser,
  ButtonWithStates
} from '@comicrelief/component-library';

import { Arrow } from '@comicrelief/component-library/dist/components/Atoms/Icons';
import FurtherInfo from '../../components/Prize/FurtherInfo';

import {
  Body,
  ButtonHolder,
  CheckboxContainer,
  CheckboxList,
  CheckboxStyled,
  Columns,
  Container,
  Details,
  FieldRow,
  FormContainer,
  Flag,
  Grid,
  Header,
  MoneyBox,
  MoneyBuyText,
  PictureHolder,
  PrizeCard,
  PrizeFooter,
  PrizeWidgetAlignmentWrapper,
  Row,
  ShareContainer,
  TermsCopy,
  Widget,
  WinnersContainer,
  WinnersWrapper,
  Wrapper
} from '../../components/Prize/Prize.styles';

import {
  Grid as GridWinners,
  Items,
  Title
} from '../../components/Prize/Prizes.styles';

import {
  prizeStatus,
  queryPrizeTicketsCreate,
  getPrizeTicketsCreateSchema,
  prizePlatformStatus,
  // handlePrizeCapi,
  handleRefresh
} from '../../components/Prize/_utils';

import Layout from '../../components/Layout/Layout';
import SEO from '../../components/SEO/SEO';
import renderRichText from '../../components/RichText/RichText';
import ThankYou from '../../components/Prize/ThankYou';
import Success from '../../components/Prize/Success';
import Failure from '../../components/Prize/Failure';
import ServiceDown from '../../components/Prize/ServiceDown';
import MarketingPreferencesForm from '../../components/Prize/MarketingPreferencesForm';

const Prize = ({ data }) => {
  const [total, setTotal] = useState(0.00);
  const [showDetails, setShowDetails] = useState(false);
  const [isClosed, setIsClosed] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  const [isPaymentFailure, setIsPaymentFailure] = useState(false);
  const [supporterName, setSupporterName] = useState(null);
  const [numberOfTickets, setNumberOfTickets] = useState(0);
  const [daysLeft, setDaysLeft] = useState(null);
  const [transaction, setTransaction] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [urlReturn, setUrlReturn] = useState(null);
  const [serviceLoaded, setServiceLoaded] = useState(false);
  const [isServiceDown, setIsServiceDown] = useState(null);
  const [prizeEnded, setPrizeEnded] = useState(false);

  const { prize: { edges }, prizeWinners } = data;
  const prize = edges[0]?.node;
  const cardRef = useRef(null);
  const termsRef = useRef(null);

  if (!prize) {
    return null;
  }

  const {
    contentfulId,
    title,
    featuredImage,
    body,
    summary,
    pastBody,
    path,
    endDate,
    termsConditions,
    consentCampaign,
    entryByPost,
    faQs,
    bundles,
    category,
    moneybuys,
    financialAttribute: {
      campaign: {
        campaignCode
      }
    }
  } = prize;

  const seoSummary = 'Win incredible, money-can\'t buy prizes and experiences whilst taking on poverty and injustice at the same time. Enter now. Good luck!';
  const seoImage = featuredImage?.seoImage?.src;
  const fluidImage = featuredImage?.fluid?.srcSet;
  const lowImage = featuredImage?.placeHolder?.src;
  const description = featuredImage?.description;

  /* Prize form config */
  const schema = getPrizeTicketsCreateSchema();
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    shouldFocusError: true
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue
  } = methods;

  const handleScroll = (e) => {
    if (e !== undefined) {
      e.preventDefault();
    }
    cardRef.current.scrollIntoView(
      {
        behavior: 'smooth',
        block: 'start'
      }
    );
  };

  const handleGtm = (price, step, transactionId = null, ticketNum = null) => {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    switch (step) {
      // Triggered on page load:
      case 1:
        // GA4 event, uses 1st bundle as the default before a user selects anything
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'view_item',
          ecommerce: {
            // 'value' represents TOTAL value of the purchase:
            value: parseFloat(bundles[0].price).toFixed(2),
            currency: 'GBP',
            items: [{
              item_id: contentfulId,
              item_name: title,
              affiliation: 'cr-prizes',
              item_brand: 'comicrelief',
              item_category: category.name,
              item_list_id: 'prize_homepage',
              item_list_name: 'prize homepage',
              item_variant: `${bundles[0].tickets}-entry`, // 1st bundle as default
              // 'price' is equivalent to cost PER entry:
              price: parseFloat(bundles[0].price / bundles[0].tickets).toFixed(2),
              quantity: bundles[0].tickets
            }]
          }
        });
        break;
      // Triggered on submit:
      case 2:
        // GA4 event
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'select_item',
          ecommerce: {
            items: [{
              item_id: contentfulId,
              item_name: title,
              affiliation: 'cr-prizes',
              item_brand: 'comicrelief',
              item_category: category.name,
              item_list_id: 'prize_homepage',
              item_list_name: 'prize homepage',
              item_variant: `${ticketNum}-entry`,
              price: parseFloat(price / ticketNum).toFixed(2),
              quantity: ticketNum
            }]
          }
        });
        break;
      // Also triggered on submit:
      case 3:
        // GA4 event
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'begin_checkout',
          ecommerce: {
            // 'value' represents TOTAL value of the purchase
            value: parseFloat(price).toFixed(2),
            currency: 'GBP',
            items: [{
              item_id: contentfulId,
              item_name: title,
              affiliation: 'cr-prizes',
              item_brand: 'comicrelief',
              item_category: category.name,
              item_list_id: 'prize_homepage',
              item_list_name: 'prize homepage',
              item_variant: `${ticketNum}-entry`,
              // 'price' is equivalent to cost PER entry
              price: parseFloat(price / ticketNum).toFixed(2),
              quantity: ticketNum
            }]
          }
        });
        break;
      // Triggered on successful payment:
      case 4:
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'add_payment_info',
          ecommerce: {
            value: parseFloat(price).toFixed(2),
            currency: 'GBP',
            items: [{
              item_id: contentfulId,
              item_name: title,
              affiliation: 'cr-prizes',
              item_brand: 'comicrelief',
              item_category: category.name,
              item_list_id: 'prize_homepage',
              item_list_name: 'prize homepage',
              item_variant: `${ticketNum}-entry`,
              // 'price' is equivalent to cost PER entry
              price: parseFloat(price / ticketNum).toFixed(2),
              quantity: ticketNum
            }]
          }
        });
        break;
      case 5:
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'purchase',
          ecommerce: {
            transaction_id: transactionId,
            value: parseFloat(price).toFixed(2),
            tax: 0.00,
            shipping: 0.00,
            currency: 'GBP',
            items: [{
              item_id: contentfulId,
              item_name: title,
              affiliation: 'cr-prizes',
              item_brand: 'comicrelief',
              item_category: category.name,
              item_list_id: 'prize_homepage',
              item_list_name: 'prize homepage',
              item_variant: `${ticketNum}-entry`,
              price: parseFloat(price / ticketNum).toFixed(2),
              quantity: ticketNum
            }]
          }
        });
        break;
      default:
        break;
    }
  };

  const handleResponse = () => {
    const query = new URLSearchParams(window.location.search);
    const payment = query.get('payment');
    if (payment != null) {
      const opt = query.get('opt');
      const supporterInfo = JSON.parse(atob(opt));
      const { amount: ticketNum } = supporterInfo;
      const transactionID = query.get('transaction_id');
      const prizeTotal = JSON.parse(localStorage.getItem('prizeTotal'));

      if (payment === 'success') {
        setTransaction(transactionID);
        handleGtm(prizeTotal, 4, transactionID, ticketNum);
        handleGtm(prizeTotal, 5, transactionID, ticketNum);

        setSupporterName(supporterInfo.supporter.firstName);
        setIsSuccess(true);
        // SERVICE STILL BROKEN, COMMENTING OUT FOR NOW:
        // handlePrizeCapi(ticketNum, transactionID, contentfulId, prize.bundles);
        setTimeout(() => {
          handleScroll();
        }, 100);
      }

      if (payment === 'failure') {
        setIsFailure(true);
        setIsPaymentFailure(true);
        setTimeout(() => {
          handleScroll();
        }, 100);
      }
      // Return values to act as GA4 event flag
      return true;
    }
    return false;
  };

  const handleError = (err) => {
    Sentry.captureException(err);
    setIsFailure(true);
    setIsSubmitting(false);
    setTimeout(() => {
      handleScroll();
    }, 100);
  };

  const onSubmit = async (formData) => {
    try {
      handleGtm(total, 2, null, numberOfTickets);
      handleGtm(total, 3, null, numberOfTickets);
      setIsSubmitting(true);
      const thisUserObject = formData.supporter;
      thisUserObject.campaign = campaignCode;
      localStorage.setItem('userDetails', JSON.stringify(thisUserObject));

      // To give GA events access to this value after successful payment:
      localStorage.setItem('prizeTotal', JSON.stringify(total));

      const newTicket = await queryPrizeTicketsCreate(formData);
      const url = newTicket && newTicket.data.data.redirectURL;

      // Catch if return url is undefined, empty string, etc.
      if (!url) {
        throw new Error('No URL found for prize');
      }

      window.location.href = url; // Redirects to payment platform
    } catch (err) {
      handleError(err);
    }
  };

  const getMoneyBuy = () => {
    const matchingMoneyBuy = moneybuys?.find(
      moneybuy => parseFloat(moneybuy.value) === parseFloat(total)
    );

    if (total > 0 && !matchingMoneyBuy) {
      return null;
    }

    return (
      <MoneyBuyText>
        {matchingMoneyBuy ? (
          <Text tag="p" color="black">
            <Text tag="span" weight="bold" color="black">
              £
              {matchingMoneyBuy.value}
              {' '}
            </Text>
            {matchingMoneyBuy.description}
          </Text>
        ) : (
          <Text tag="p" color="black">
            Select a bundle to get started!
          </Text>
        )}
      </MoneyBuyText>
    );
  };

  useEffect(() => {
    // Update the hidden field the PROPER way:
    setValue('amount', numberOfTickets);
  }, [numberOfTickets]);

  useEffect(() => {
    const platformService = prizePlatformStatus();
    const cleanUrl = window.location.origin + window.location.pathname;
    platformService.then((res) => {
      setServiceLoaded(res.status === 200);
    }).catch(() => {
      setIsServiceDown(true);
    });

    const paymentAttempted = handleResponse();
    setUrlReturn(cleanUrl);

    // Only trigger our first GA4 event if this page load ISN'T a return from the Payment page:
    if (!paymentAttempted) {
      handleGtm(0, 1);
    }
  }, []);

  useEffect(() => {
    setDaysLeft(prizeStatus(endDate));
    const today = moment();
    setPrizeEnded(moment(endDate).diff(today) < 0);
  }, [endDate]);

  if (daysLeft === null || prizeEnded) {
    return (
      <Layout removeDonateLink>
        <SEO
          title={`${title} | Comic Relief`}
          description={seoSummary}
          image={seoImage}
          pathname={`prizes/${category.name}/${path}`}
        />
        {daysLeft === null ? (
          <Container>
            <Body>
              <Text>Loading...</Text>
            </Body>
          </Container>
        ) : (
          <>
            <ThankYou
              title={title}
              body={body ? renderRichText(body, 'white', 'prize') : null}
              pastBody={pastBody ? renderRichText(pastBody, 'white', 'prize') : null}
              summary={renderRichText(summary, 'white', 'prize')}
              termsRef={termsRef}
              entryByPost={entryByPost}
              termsConditions={termsConditions}
              faQs={faQs}
            />
          </>
        )}
        {prizeWinners && prizeWinners.edges.length > 2 ? (
          <WinnersWrapper>
            <WinnersContainer>
              <Title>
                <Text tag="h2" font="Anton" uppercase size="xl">See the other winning stories</Text>
              </Title>
              <GridWinners>
                {prizeWinners && prizeWinners.edges.map(({ node }) => {
                  const {
                    featuredImage: featuredImageWin,
                    path: pathWin,
                    title: titleWin,
                    id: idWin,
                    category: categoryWin
                  } = node;

                  const fallback = featuredImageWin && featuredImageWin.fallback.src;
                  const images = featuredImageWin && featuredImageWin.fluid.srcSet;
                  const imageLow = featuredImageWin && featuredImageWin.placeHolder.src;
                  const descriptionWin = featuredImageWin ? featuredImageWin.description : '';
                  const URL = `/prizes/${kebabCase(categoryWin.name)}/${pathWin}`;

                  return (
                    <Items key={idWin}>
                      <ArticleTeaser
                        href={URL}
                        image={fallback}
                        images={images}
                        imageLow={imageLow}
                        title={titleWin}
                        alt={descriptionWin}
                        time=""
                      />
                    </Items>
                  );
                })}
              </GridWinners>
            </WinnersContainer>
          </WinnersWrapper>
        ) : null }
        <Wrapper>
          <Container>
            <Link
              color="black"
              type="button"
              href="/prizes"
              target="self"
              iconFirst
              icon={<Arrow direction="left" colour="white" />}
            >
              Back to all prizes
            </Link>
          </Container>
        </Wrapper>
      </Layout>
    );
  }

  return (
    <Layout removeDonateLink>
      <SEO
        title={`${title} | Comic Relief`}
        description={seoSummary}
        image={seoImage}
        pathname={`prizes/${category.name}/${path}`}
      />
      <Wrapper data-test="prize-page">
        <Container>
          <Header>
            <Text tag="h1" family="Anton" size="big" uppercase>
              {title}
            </Text>
            <ShareContainer data-test="social-share">
              <ShareButton copy="Share this prize" />
            </ShareContainer>
          </Header>
          <Body>
            {summary ? renderRichText(summary, 'white', 'prize') : null}
          </Body>
          <PrizeCard data-test="prize-card">
            {(isSuccess || !!isFailure) ? (
              <Columns>
                <Picture
                  alt={description}
                  imageLow={lowImage}
                  images={fluidImage}
                  objectFit="cover"
                />
                <Widget data-test="prize-widget" ref={cardRef}>
                  {isSuccess ? (
                    <Success name={supporterName} transaction={transaction} url={`${kebabCase(category.name)}/${path}`} handleRefresh={handleRefresh} />
                  ) : (
                    <Failure url={`/prizes/${kebabCase(category.name)}/${path}`} paymentFailure={isPaymentFailure} />
                  )}
                </Widget>
              </Columns>
            ) : (
              <FormProvider {...methods}>
                <FormContainer>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <input
                      type="hidden"
                      {...register('prize_name')}
                      name="prize_name"
                      value={title}
                    />
                    <input
                      type="hidden"
                      {...register('prize_id')}
                      name="prize_id"
                      value={contentfulId}
                    />
                    <input
                      type="hidden"
                      {...register('amount')}
                      name="amount"
                    />
                    <input
                      type="hidden"
                      {...register('supporter.country')}
                      name="supporter.country"
                      value="GB"
                    />
                    <input
                      type="hidden"
                      {...register('urls.success')}
                      name="urls.success"
                      value={urlReturn.endsWith('/') ? urlReturn : `${urlReturn}/`}
                    />
                    <input
                      type="hidden"
                      {...register('urls.failure')}
                      name="urls.failure"
                      value={urlReturn.endsWith('/') ? urlReturn : `${urlReturn}/`}
                    />
                    <Columns $startAlign>
                      <PictureHolder>
                        {daysLeft && (
                          <Flag>
                            <Text size="s" weight="bold">
                              {daysLeft}
                            </Text>
                          </Flag>
                        )}
                        <Picture
                          alt="test Image"
                          imageLow={lowImage}
                          images={fluidImage}
                          objectFit="cover"
                        />
                      </PictureHolder>
                      <PrizeWidgetAlignmentWrapper>
                        <Widget data-test="prize-widget" ref={cardRef}>
                          {!serviceLoaded ? (
                            <ServiceDown isServiceDown={isServiceDown} />
                          ) : (
                            <>
                              <Text tag="h2" size="xl" uppercase>
                                Choose your entries
                              </Text>
                              <Grid>
                                {bundles.map((ticket) => {
                                  const { tickets, price } = ticket;
                                  return (
                                    <MoneyBox
                                      showLabel
                                      label={`
                                        <span class="moneybox-label">
                                          <b>${tickets} ${tickets === '1' ? 'entry' : 'entries'}</b>
                                          <span class="moneybox-price">£${price}</span>
                                          <span class="moneybox-entry">£${price / tickets} per entry</span>
                                        </span>`}
                                      id={`"${tickets}"`}
                                      isSelected={total === price}
                                      aria-label=""
                                      key={tickets}
                                      name="tickets"
                                      type="button"
                                      value={price}
                                      errorMsg=""
                                      onClick={(e) => {
                                        if (isClosed) {
                                          setShowDetails(true);
                                          setIsClosed(false);
                                        }
                                        handleScroll(e);
                                        setTotal(price);
                                        setNumberOfTickets(parseInt(tickets, 10));
                                      }}
                                    />
                                  );
                                })}
                              </Grid>
                              {moneybuys && getMoneyBuy()}
                            </>
                          )}
                        </Widget>
                      </PrizeWidgetAlignmentWrapper>
                    </Columns>
                    <Details data-test="prize-form" showDetails={showDetails}>
                      <Text tag="h2" size="xl" uppercase>
                        Enter your details
                      </Text>
                      <FieldRow>
                        <Input
                          name="supporter.firstName"
                          placeholder="First Name"
                          type="text"
                          label="First Name"
                          errorMsg={errors?.supporter?.firstName?.message}
                          id="first-name"
                          showLabel
                          {...register('supporter.firstName')}
                        />
                        <Input
                          name="supporter.lastName"
                          placeholder="Last Name"
                          type="text"
                          label="Last Name"
                          errorMsg={errors?.supporter?.lastName?.message}
                          id="last-name"
                          showLabel
                          {...register('supporter.lastName')}
                        />
                      </FieldRow>
                      <FieldRow>
                        <Input
                          name="supporter.email"
                          placeholder="Email"
                          type="email"
                          label="Email"
                          errorMsg={errors?.supporter?.email?.message}
                          id="email"
                          showLabel
                          {...register('supporter.email')}
                        />
                        <Input
                          name="supporter.mobile"
                          placeholder="Format: 07123456789"
                          type="tel"
                          label="Mobile"
                          errorMsg={errors?.supporter?.mobile?.message}
                          id="mobile"
                          showLabel
                          {...register('supporter.mobile')}
                        />
                      </FieldRow>
                      <FieldRow>
                        <Input
                          name="supporter.address1"
                          placeholder="Address Line 1"
                          type="text"
                          label="Address Line 1"
                          errorMsg={errors?.supporter?.address1?.message}
                          id="address1"
                          showLabel
                          {...register('supporter.address1')}
                        />
                        <Input
                          name="supporter.town"
                          placeholder="Town"
                          type="text"
                          label="Town"
                          errorMsg={errors?.supporter?.town?.message}
                          id="town"
                          showLabel
                          {...register('supporter.town')}
                        />
                      </FieldRow>
                      <FieldRow>
                        <Input
                          name="supporter.postcode"
                          placeholder="Postcode"
                          type="text"
                          label="Postcode"
                          errorMsg={errors?.supporter?.postcode?.message}
                          id="postcode"
                          showLabel
                          {...register('supporter.postcode')}
                        />
                      </FieldRow>
                      <div style={{ marginBottom: '2rem', color: 'white' }}>
                        <CheckboxStyled>
                          <CheckboxContainer>
                            <Checkbox id="consent" name="consent" {...register('consent')} />
                          </CheckboxContainer>
                          <CheckboxList>
                            {consentCampaign.map(consent => (
                              <div key={`consent-${camelCase(consent.title)}`}>
                                { renderRichText(consent.copy, 'white', 'prize') }
                              </div>
                            ))}
                          </CheckboxList>
                          <Text color="red" weight="bold">
                            {errors?.consent?.message}
                          </Text>
                        </CheckboxStyled>
                      </div>
                    </Details>
                    {showDetails && (
                      <ButtonHolder>
                        <ButtonWithStates
                          type="submit"
                          loading={isSubmitting}
                          disabled={isSubmitting}
                        >
                          Enter prize draw
                        </ButtonWithStates>
                      </ButtonHolder>
                    )}
                    { !(isSuccess || isFailure) ? (
                      <TermsCopy showDetails={showDetails}>
                        Find out more about how we use your personal data at
                        {' '}
                        <Link href="https://comicrelief.com/privacy" type="standard" target="blank">comicrelief.com/privacy</Link>
                        . You can also enter for free by post.
                      </TermsCopy>
                    ) : null }
                  </form>
                </FormContainer>
              </FormProvider>
            )}

            {/* Separate form for Marketing Prefs HERE */}
            { (isSuccess || isFailure) && (
            <MarketingPreferencesForm handleScroll={handleScroll} />
            )}
          </PrizeCard>

          <Body>
            {body ? renderRichText(body, 'white', 'prize') : null}
          </Body>

          <FurtherInfo
            termsRef={termsRef}
            entryByPost={entryByPost}
            termsConditions={termsConditions}
            faQs={faQs}
          />

          {!isSuccess && (
            <PrizeFooter>
              <Row>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  href="#"
                  color="red"
                  type="button"
                  onClick={e => handleScroll(e)}
                  icon={<Arrow direction="up" colour="white" />}
                >
                  Enter now
                </Link>
              </Row>
              <Link
                color="black"
                type="button"
                href="/prizes"
                target="self"
                iconFirst
                icon={<Arrow direction="left" colour="white" />}
              >
                Back to all prizes
              </Link>
            </PrizeFooter>
          )}
        </Container>
      </Wrapper>
    </Layout>
  );
};

Prize.propTypes = {
  data: PropTypes.shape({
    prize: PropTypes.shape({
      edges: PropTypes.shape([])
    }).isRequired,
    prizeWinners: PropTypes.shape({
      edges: PropTypes.shape([])
    })
  }).isRequired
};

export default Prize;

export const pageQuery = graphql`
  query getPrize($slug: String) {
    prize: allContentfulPrizes(filter: { path: { eq: $slug } }) {
      edges {
        node {
          contentfulId: contentful_id
          path
          title
          startDate(formatString: "Do MMMM YYYY")
          endDate
          bundles {
            tickets
            price
          }
          category {
            name
          }
          financialAttribute {
            cart {
              cartId
            }
            campaign {
              campaignCode
            }
          }
          summary {
            raw
          }
          body {
            raw
          }
          moneybuys {
            contentful_id
            summary
            value
            description
            expiryDate
          }
          pastBody {
            raw
            references {
              __typename
              ...Asset
              ...HtmlCode
            }
          }
          termsConditions {
            raw
          }
          faQs {
            raw
          }
          entryByPost {
            raw
          }
          consentCampaign {
            title
            copy {
              raw
            }
          }
          featuredImage {
            ...Picture
          }
        }
      }
    }
    prizeWinners: allContentfulPrizes(filter: {winner: {eq: true}}) {
      edges {
        node {
          title
          path
          id
          category {
            name
          }
          winner
          featuredImage {
            ...Picture
          }
        }
      }
    }
  }
`;
