import React from 'react';
import {
  Text,
  Accordion
} from '@comicrelief/component-library';
import PropTypes from 'prop-types';
import renderRichText from '../RichText/RichText';

import {
  Row,
  MoreInfo
} from './Prize.styles';

const getFaqAndTermsTitle = (faQs, termsConditions) => {
  if (faQs && termsConditions) return 'FAQs & Terms and conditions';
  if (faQs) return 'FAQs';
  if (termsConditions) return 'Terms and conditions';
  return '';
};

const FurtherInfo = ({
  termsRef, entryByPost, termsConditions, faQs
}) => {
  const moreInfoTitle = getFaqAndTermsTitle(faQs, termsConditions);
  return (
    <>
      {moreInfoTitle && (
        <MoreInfo ref={termsRef}>
          <Text tag="h2" size="xl" uppercase>
            {moreInfoTitle}
          </Text>
        </MoreInfo>
      )}
      {entryByPost
        ? (
          <Row>
            <Accordion
              title={(
                <Text family="Anton" size="l" uppercase>
                  Enter for free by post
                </Text>
                  )}
            >
              {renderRichText(entryByPost, 'white', 'SingleMessageDs')}
            </Accordion>
          </Row>
        )
        : null}
      {termsConditions
        ? (
          <Row id="terms-conditions">
            <Accordion
              title={(
                <Text family="Anton" size="l" uppercase>
                  Terms & Conditions
                </Text>
                  )}
            >
              {renderRichText(termsConditions, 'white', 'SingleMessageDs')}
            </Accordion>
          </Row>
        )
        : null}
      {faQs
        ? (
          <Row>
            <Accordion
              title={(
                <Text family="Anton" size="l" uppercase>
                  Frequently Asked Questions
                </Text>
                  )}
            >
              {renderRichText(faQs, 'white', 'SingleMessageDs')}
            </Accordion>
          </Row>
        )
        : null}
    </>
  );
};

FurtherInfo.propTypes = {
  termsRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape()
  ]),
  entryByPost: PropTypes.shape(),
  termsConditions: PropTypes.shape(),
  faQs: PropTypes.shape()
};

export default FurtherInfo;
