import styled, { css } from 'styled-components';
import {
  Input,
  containers,
  spacing,
  zIndex,
  Text
} from '@comicrelief/component-library';

import ticket from '../../../static/assets/ticket.svg';
import ticketActive from '../../../static/assets/ticket-active.svg';
import ticketFocus from '../../../static/assets/ticket-focus.svg';

const Wrapper = styled.div`
  background: ${({ theme }) => theme.color('grey_light')};
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.font.regular};
`;

const Container = styled.article.attrs({
  'data-test': 'prize'
})`
  width: 100%;
  max-width: ${containers.medium};
  margin: ${spacing('l')} auto;
  padding: ${spacing('md')};
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    padding: ${spacing('l')} 5.25rem ${spacing('lg')};
  }
`;

const WinnersWrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.color('grey_light')};
`;

const WinnersContainer = styled.div`
  width: 100%;
  max-width: ${containers.medium};
  padding: 0 ${spacing('md')} ${spacing('md')};
  margin: 0 auto;
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    padding: 0 ${spacing('m')} ${spacing('l')};
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing('l')};
  flex-direction: column;
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    flex-direction: row;
    margin-bottom: ${spacing('lg')};
  }
  h1 {
    font-size: ${({ theme }) => theme.fontSize('xxl')};
    line-height: ${({ theme }) => theme.fontSize('xxl')};
    margin-bottom: ${spacing('l')};
    @media ${({ theme }) => theme.allBreakpoints('L')} {
      margin-bottom: 0;
      margin-right: 8rem;
      font-size: ${({ theme }) => theme.fontSize('big')};
      line-height: ${({ theme }) => theme.fontSize('big')};
    }
  }
`;

const ShareContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: flex-start;
  justify-content: center;
`;

const Body = styled.div`
  width: 100%;
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    max-width: ${containers.small};
  }
  margin-bottom: 5rem;
`;

const PrizeCard = styled.div`
  position: relative;
  padding: ${spacing('l')};
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  box-shadow: 0px 0px 20px rgba(0,0,0,0.15);
  background: ${({ theme }) => theme.color('white')};
  margin-bottom: 3rem;

  img {
    border-radius: 1rem;
    margin-bottom: 1rem;
  }
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    padding: ${spacing('lg')};
  }
`;

const MoneyBox = styled(Input)`
  position: relative;
  width: 132px;
  &:hover {
    input {
      outline: none;
      border: none;
      color: grey;
      background: url(${ticketFocus}) no-repeat 100%;
    }
  }
  .moneybox-label {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    color: black;
    font-size: 1.25rem;
    text-align: center;
    align-items: center;
    ${zIndex('medium')};
    margin: auto;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 70px;
    ${({ isSelected }) => (isSelected ? 'color: white' : null)};
    b {
      width: 50%;
      font-size: 0.75rem;
      width: 100%;
      text-transform: capitalize;
    }
    .moneybox-price {
      font-size: 2rem;
      font-weight: normal;
      font-family: "Anton";
      width: 100%;
    }
    .moneybox-entry {
      font-size: 0.75rem;
      width: 100%;
      font-weight: normal;
    }
  }
  div {
    margin: 0;
  }
  input {
    position: initial;
    padding: ${spacing('sm')} ${spacing('m')};
    border: none;
    background: url(${ticket}) no-repeat 100%;
    height: 100px;
    text-indent: -9999rem;
    @media ${({ theme }) => theme.allBreakpoints('M')} {
      font-size: ${({ theme }) => theme.fontSize('xl')};
    }
    &:focus {
      outline: none;
      border: none;
      color: grey;
      background: url(${ticketFocus}) no-repeat 100%;
    }
    &:active:focus {
      background: url(${ticketActive}) no-repeat 100%;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.3);
      border: none;
    }

    ${({ isSelected }) => isSelected
      && css`
        background: url(${ticketActive}) no-repeat 100%;
        &:focus,
        &:hover {
          border: none;
          background: url(${ticketActive}) no-repeat 100%;
        }
      `}
  }
`;

const Details = styled.div`
  display: ${({ showDetails }) => (showDetails ? 'block' : 'none')};
  border-top: 2px solid ${({ theme }) => theme.color('grey_medium')};
  padding-top: ${spacing('lg')};
  margin-top: ${spacing('lg')};
  input {
    max-width: 100%;
  }
`;

const Columns = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  @media ${({ theme }) => theme.allBreakpoints('L')} {
    flex-direction: row;
  }
  img {
    margin: 0;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: ${spacing('l')};
  label {
    margin-bottom: ${spacing('l')};
  }
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    width: 52%;
  }
`;

const FieldRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  label {
    margin-bottom: ${spacing('md')};
    width: 100%;
  }
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    flex-direction: row;
    label {
      width: calc(50% - ${spacing('m')});
      margin-bottom: ${spacing('l')};
    }
  }
`;

const FormContainer = styled.div`
  position: relative;
`;

const PictureHolder = styled.div`
  position: relative;
  align-self: start;
  margin-top: -2rem;
  width: calc(100% + 4rem);
  img {
    border-radius: 1rem 1rem 0 0;
  }
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    width: auto;
    margin-top: 0;
    img {
      border-radius: 1rem;
    }
  }
  @media ${({ theme }) => theme.allBreakpoints('L')} {
    width: 50%;
  }
`;

const Widget = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 50%;
  align-items: center;
  text-align: center;
  #tickets {
    max-width: 150px;
  }
  margin-top: ${spacing('l')};
  @media ${({ theme }) => theme.allBreakpoints('L')} {
    margin-top: 0;
    margin-left: ${spacing('lg')};
  }
`;

const Grid = styled.div`
  display: grid;
  -ms-grid-columns: 132px 132px 132px;
  grid-template-columns: repeat(2, 132px);
  grid-column-gap: ${spacing('sm')};
  grid-row-gap: ${spacing('sm')};
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    grid-template-columns: repeat(3, 132px);
  }
`;

const CheckboxContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  label {
    margin: 0;
  }
`;

const CheckboxStyled = styled.div`
  color: ${({ theme }) => theme.color('black')};
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  align-items: center;
  span { // This is an error for the checkbox, if displayed. Set to appear below the label text.
    flex-shrink: 0;
    grid-column: 2;
    grid-row: 2;
    padding: 0 0 0 ${spacing('md')};
  }
  p {
    font-size: ${({ theme }) => theme.fontSize('s')};
    margin-top: 0.20rem;
    grid-column: 2;
    grid-row: 1;
    @media ${({ theme }) => theme.allBreakpoints('M')} {
      font-size: ${({ theme }) => theme.fontSize('s')};
    }
  }
`;

const CheckboxList = styled.div`
  padding-left: ${spacing('md')};
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    padding: 0 0 0 ${spacing('md')};
  }
`;

const Total = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${spacing('l')};
`;

const ButtonHolder = styled.div`
  position: absolute;
  right: -${spacing('sm')};
  bottom: -${spacing('xl')};
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    button {
      margin-bottom: 0;
    }
  }
`;

const Row = styled.div`
  margin-bottom: ${spacing('l')};
`;

const PrizeFooter = styled.div`
  margin: ${spacing('lg')} 0;
`;

const PrizeWidgetAlignmentWrapper = styled(Wrapper)`
  align-items: center;
  @media ${({ theme }) => theme.allBreakpoints('L')} {
    align-items: flex-start;
  }
  background: ${({ theme }) => theme.color('white')};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  flex: 1;
`;

const MoreInfo = styled.div`
  margin-bottom: ${spacing('xl')};
`;

const TermsButton = styled.button`
  background: none;
  border: none;
  font-size: ${({ theme }) => theme.fontSize('s')};
  outline: 0;
  padding: 0;
  border-bottom: 2px solid ${({ theme }) => theme.color('black')};
`;

const TermsCopy = styled.div`
  display: ${({ showDetails }) => (showDetails ? 'block' : 'none')};
  margin-bottom: ${spacing('l')};
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    margin-bottom: 0;
  }
`;

const Flag = styled.div`
  display: flex;
  background: ${({ theme }) => theme.color('yellow')};
  padding: ${spacing('sm')} ${spacing('m')} ${spacing('sm')} ${spacing('l')};
  position: absolute;
  top: 0;
  right: 0;
  ${zIndex('low')};
  border-radius: 0 14px 0 0;
  clip-path: polygon(100% 0,100% 50%,100% 100%,0% 100%,10% 50%,0% 0%);
`;

const LookupSearch = styled.div`
  margin-bottom: ${spacing('m')};
  width: 100%;
  max-width: 50%;
`;

const MarketingPrefsWrapper = styled.div`
  > div > div:first-child {
    text-align: center;
  }
  margin-top: ${spacing('lg')};
`;

const MarketingPrefsSubmitCopy = styled(Text)`
  text-align: center;
`;

const MarketingPrefsShowHide = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

const MoneyBuyText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: ${spacing('l')};
`;

export {
  Body,
  ButtonHolder,
  CheckboxContainer,
  CheckboxList,
  CheckboxStyled,
  Column,
  Columns,
  Container,
  Details,
  FieldRow,
  Flag,
  FormContainer,
  Grid,
  Header,
  LookupSearch,
  MarketingPrefsShowHide,
  MarketingPrefsSubmitCopy,
  MarketingPrefsWrapper,
  MoneyBox,
  MoneyBuyText,
  MoreInfo,
  PictureHolder,
  PrizeCard,
  PrizeFooter,
  Row,
  ShareContainer,
  TermsButton,
  TermsCopy,
  PrizeWidgetAlignmentWrapper,
  Total,
  Widget,
  WinnersContainer,
  WinnersWrapper,
  Wrapper
};
