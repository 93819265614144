import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  spacing,
  MarketingPreferencesDS,
  TextArea,
  Label,
  RadioButton
} from '@comicrelief/component-library';

import { snakeCase } from 'lodash';
import {
  Button,
  SoftHider,
  Divider,
  SubmitContainer,
  DescriptionWrapper
} from './FundraiserSignup.style';

import {
  FIELDS,
  FUNDRAISING_GROUPS,
  PRINTED_FUNDRAISING_PACK,
  PACK_SEGMENTS,
  CAMPAIGN_STRING
} from './constants';

import mpTopCopy from './mpCopy';

import BackButton from './components/BackButton';
import Fieldset from '../../components/Fieldset/Fieldset';
import Grid from '../../components/Grid/Grid';
import TermsAndConditions from './components/TermsAndConditions';
// import PrizeDrawOptIn from './components/PrizeDrawOptIn';
import Target from './components/Target';
import renderBody from '../../components/RichText/RichText';
import ProgressBar from './components/ProgressBar';
import FundraiserSignupContext from './context/FundraiserSignupContext';
import { MpValidationOptionsPropTypes } from './PropTypes';

const FundraiserSignupStep3 = ({
  title,
  mpValidationOptions,
  showJGQuestion,
  justGivingLabel,
  goalsTitle,
  showFundraisingPackQuestion,
  fundraisingPackSegments,
  campaignCode,
  description = 'Tell us about your plans',
  justGivingDescription = 'Tell us more about your JustGiving page',
  goalsDescription = 'Tell us more about your fundraising goals',
  options = {
    goals: { first: '£250', second: '£500', third: '£1000' }
  }
}) => {
  const {
    step,
    register,
    getError,
    watch,
    fundraisingGroup,
    setStep,
    setValue,
    isSubmitting,
    methods
  } = useContext(FundraiserSignupContext);
  const firstName = watch(FIELDS.FIRST_NAME);
  const orgName = watch(FIELDS.ORG_NAME);
  const agreedToTerms = watch(FIELDS.OVER_EIGHTEEN);
  const selectedFundraisingSegment = watch(FIELDS.SEGMENT);
  const defaultFundraisingPackChoice = watch(FIELDS.FUNDRAISING_PACK);
  const isSchool = fundraisingGroup === FUNDRAISING_GROUPS.SCHOOL;
  const { first, second, third } = options.goals;
  const [mappedPackSegments, setMappedPackSegments] = useState(false);

  // Initially, just use the 'global' CMS option for this functionality:
  let showPackFiltered = showFundraisingPackQuestion;

  // On initial mount only...
  useEffect(() => {
    // ... check if we have specified any 'show FR pack option' segments in the CMS first:
    if (fundraisingPackSegments) {
      const segmentsToShowPackOption = [];

      // If so, for each group we want to show the FR pack option to, check it's valid and use the
      // 'internal' mapped name (for ease of comparison later), storing it in our state array:
      fundraisingPackSegments.forEach((thisGroup) => {
        const convertedName = snakeCase(thisGroup);
        if (PACK_SEGMENTS[convertedName]) {
          segmentsToShowPackOption.push(PACK_SEGMENTS[convertedName]);
        }
      });
      setMappedPackSegments(segmentsToShowPackOption);
    }
  }, [fundraisingPackSegments]);

  useEffect(() => {
    if (orgName || firstName) {
      const thisSuffix = `'s ${CAMPAIGN_STRING(campaignCode) || 'Page'}`;
      const thisName = orgName || firstName;
      // Strip out any chars not allowed by the page_title validation, and
      // trim the string to suit the backend.
      const thisNameFormatted = thisName.replace(/[^a-zA-Z0-9!?$£@:;'",.-\s]+/g, '')
        .substring(0, 100 - thisSuffix.length);

      setValue(FIELDS.PAGE_TITLE, `${thisNameFormatted}${thisSuffix}`);
    }
  }, [firstName, orgName]);

  // Never show JG content for schools, but also only if we've
  // explictly set this in the CMS for other audience groups.
  const renderJGContent = !(isSchool) && showJGQuestion;

  // If the CMS has passed specific groups/segments to show the FR pack question to,
  // check if the current segment is in that list:
  if (showFundraisingPackQuestion && mappedPackSegments) {
    showPackFiltered = mappedPackSegments.includes(selectedFundraisingSegment);
  }

  useEffect(() => {
    // Map the current 'show' boolean value to one of
    // our string values used by the radio buttons:
    const newFundraisingPackChoice = showPackFiltered
      ? PRINTED_FUNDRAISING_PACK.YES.value
      : PRINTED_FUNDRAISING_PACK.NO.value;

    // Update the state accordingly if there's been a change to
    // audience, and therefore defaultFundraisingPackChoice:
    if (defaultFundraisingPackChoice !== newFundraisingPackChoice) {
      setValue(FIELDS.FUNDRAISING_PACK, newFundraisingPackChoice);
    }
  }, [showPackFiltered]);

  return (
    <SoftHider show={step === 3} data-test="step-3">
      <BackButton onClick={() => setStep(2)} data-test="back-2" />
      <ProgressBar step={step} />

      {/* Fundraising pack question shown/hide based on CMS settings */}
      <SoftHider show={showPackFiltered} data-test="fr-pack-fields">
        <Fieldset
          legend={title}
        >
          {description && (
            <DescriptionWrapper>
              {renderBody(description)}
            </DescriptionWrapper>
          )}
          <Label label="Would you like a printed fundraising pack to support you with planning your fundraising event?">
            <RadioButton
              {...register(FIELDS.FUNDRAISING_PACK)}
              name={FIELDS.FUNDRAISING_PACK}
              value={PRINTED_FUNDRAISING_PACK.YES.value}
              label={PRINTED_FUNDRAISING_PACK.YES.displayValue}
              data-test="fundraising-pack-yes"
            />
            <RadioButton
              {...register(FIELDS.FUNDRAISING_PACK)}
              name={FIELDS.FUNDRAISING_PACK}
              value={PRINTED_FUNDRAISING_PACK.NO.value}
              label={PRINTED_FUNDRAISING_PACK.NO.displayValue}
              data-test="fundraising-pack-no"
            />
          </Label>
        </Fieldset>
      </SoftHider>

      <SoftHider show={renderJGContent} data-test="justgiving-fields">
        <Fieldset legend={justGivingLabel}>
          {justGivingDescription && renderBody(justGivingDescription)}
          <Grid columns={1} mobileColumns={1}>
            <Input
              {...register(FIELDS.PAGE_TITLE)}
              type="text"
              name={FIELDS.PAGE_TITLE}
              label="Name your fundraising page"
              id={FIELDS.PAGE_TITLE}
              errorMsg={getError(FIELDS.PAGE_TITLE)}
              autoComplete="off"
              data-test="fundraising-page-name"
            />

            <TextArea
              css={{ marginBottom: spacing('sm') }}
              {...register(FIELDS.PAGE_STORY)}
              type="textarea"
              name={FIELDS.PAGE_STORY}
              label="Tell people more about your fundraising plans"
              id={FIELDS.PAGE_STORY}
              errorMsg={getError(FIELDS.PAGE_STORY)}
              autoComplete="off"
              rows={12}
              data-test="tell-us-more"
            />
          </Grid>
        </Fieldset>
      </SoftHider>

      <Fieldset legend={goalsTitle}>
        {goalsDescription && renderBody(goalsDescription)}
        <Target
          register={register}
          watch={watch}
          setValue={setValue}
          getError={getError}
          first={first}
          second={second}
          third={third}
          images={options.goalImages}
        />
      </Fieldset>
      <div css={{ marginBottom: spacing('xl') }}>
        <MarketingPreferencesDS
          id="marketing_prefs"
          formContext={methods}
          mpValidationOptions={mpValidationOptions}
          copyTop={mpTopCopy(campaignCode)}
        />
      </div>
      <Divider />
      {/* <PrizeDrawOptIn register={register} /> */}
      <TermsAndConditions register={register} />
      <br />
      {step === 3 && (
        <SubmitContainer>
          <Button
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting || !agreedToTerms}
            data-test="submit-3"
          >
            Sign up
          </Button>
        </SubmitContainer>
      )}
    </SoftHider>
  );
};

FundraiserSignupStep3.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.shape({ raw: PropTypes.string.isRequired }),
  showFundraisingPackQuestion: PropTypes.bool.isRequired,
  fundraisingPackSegments: PropTypes.arrayOf(PropTypes.string).isRequired, // check if it s
  mpValidationOptions: MpValidationOptionsPropTypes.isRequired,
  showJGQuestion: PropTypes.bool.isRequired,
  justGivingLabel: PropTypes.string.isRequired,
  justGivingDescription: PropTypes.shape({ raw: PropTypes.string.isRequired }),
  goalsTitle: PropTypes.string.isRequired,
  goalsDescription: PropTypes.shape({ raw: PropTypes.string.isRequired }),
  campaignCode: PropTypes.string.isRequired,
  options: PropTypes.shape({
    goalImages: PropTypes.shape({
      goal1Image: PropTypes.string,
      goal2Image: PropTypes.string,
      goal3Image: PropTypes.string,
      goalOtherImage: PropTypes.string
    }).isRequired,
    goals: PropTypes.shape({
      first: PropTypes.string,
      second: PropTypes.string,
      third: PropTypes.string
    })
  })
};

export default FundraiserSignupStep3;
