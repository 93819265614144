/* See ENG-3230 for more context */

/* TEXT COLOUR RULES */
const getsWhiteText = [
  'black',
  'red',
  'blue',
  'blue_dark',
  'coral_dark',
  'deep_violet_light',
  'deep_violet',
  'deep_violet_dark',
  'green_dark',
  'grey_dark',
  'magenta_dark',
  'orange_dark',
  'purple',
  'purple_dark',
  'teal_dark',
  'yellow_dark',
  'cwg_22_blue',
  'cwg_22_purple',
  'cwg_22_red',
  'rnd_25_ruby_red',
  'rnd_25_glitzy_grape',
  'rnd_25_glitzy_blue',
  'rnd_25_glitzy_purple',
  'rnd_25_glitzy_black',
  'rnd_25_party_blue'
];

// Works as our default if no match in the above array; just including here for completeness:
// const getsBlackText = [
//   'white',
//   'red_secondary',
//   'blue_light',
//   'coral_light',
//   'coral',
//   'green_light',
//   'green',
//   'grey',
//   'grey_stone',
//   'grey_light',
//   'magenta_light',
//   'magenta',
//   'orange_light',
//   'orange',
//   'purple_light',
//   'teal_light',
//   'teal',
//   'yellow_light',
//   'yellow',
//   'cwg_22_orange',
//   'cwg_22_yellow',
//   'rnd_23_teal',
//   'rnd_23_yellow',
//   'rnd_23_pink',
//   'rnd_25_party_pink',
//   'rnd_25_party_lilac'
// ];

/* BUTTON RULES */
const getsRedBtn = [
  'white',
  'black',
  'blue_light',
  'blue',
  'blue_dark',
  'coral_light',
  'deep_violet_light',
  'deep_violet',
  'deep_violet_dark',
  'green_light',
  'green',
  'green_dark',
  'grey',
  'grey_dark',
  'grey_stone',
  'grey_light',
  'magenta_dark',
  'orange_light',
  'purple_light',
  'purple',
  'purple_dark',
  'teal_light',
  'teal',
  'teal_dark',
  'yellow_light',
  'yellow',
  'yellow_dark',
  'cwg_22_blue',
  'cwg_22_yellow',
  'rnd_23_teal',
  'rnd_23_yellow',
  'rnd_23_pink',
  'rnd_23_black',
  'rnd_25_party_pink',
  'rnd_25_party_lilac'
];

const getsWhiteBtn = [
  'red',
  'coral_dark',
  'orange_dark',
  'cwg_22_red',
  'rnd_25_ruby_red',
  'rnd_25_glitzy_grape',
  'rnd_25_glitzy_blue',
  'rnd_25_glitzy_purple',
  'rnd_25_glitzy_black',
  'rnd_25_party_blue'
];

/* Works as our default if no match in the above arrays; just including here for completeness: */
// const getsBlackBtn = [
//   'red_secondary',
//   'coral',
//   'magenta_light',
//   'magenta',
//   'orange',
//   'cwg_22_orange'
// ];

// If the buttons background colour is equal to one of these, colour change Icon colour to white
const getsWhiteIcon = [
  'red',
  'black'
];

export {
  getsWhiteText,
  getsRedBtn,
  getsWhiteBtn,
  getsWhiteIcon
};
