import React, { useState, useRef } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import get from 'lodash/get';
import styled from 'styled-components';
import {
  Icon,
  spacing,
  zIndex,
  ESU_FIELDS
} from '@comicrelief/component-library';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { snakeCase } from 'lodash';
import HeaderIcons from './assets/HeaderIcons';
import EmailSignUp from '../EmailSignUp/EmailSignUp';
import useDisableBodyScroll from './useDisableBodyScroll';
import useOnClickOutside from './useOnClickOutside';
import { selectLabelById, selectId } from './utils';

const IconWrapper = styled.div`
  width: 35px;
  height: 35px;
  display: inline-block;
`;

const ModalWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.5);
  ${zIndex('higher')};
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  div[aria-modal='true'] {
    padding: ${spacing('l')};
  }
`;

const FormWrapper = styled.div`
  position: relative;
  max-width: 650px;
  max-height: 80vh;
  overflow-y: auto;
  margin: ${spacing('md')};

  /*  This is used to override the max-width styling of inputs and the button that comes from CL */

  ${selectLabelById(ESU_FIELDS.FIRST_NAME)},
  ${selectLabelById(ESU_FIELDS.LAST_NAME)},
  ${selectLabelById(ESU_FIELDS.EMAIL)},
  ${selectId(ESU_FIELDS.FIRST_NAME)},
  ${selectId(ESU_FIELDS.LAST_NAME)},
  ${selectId(ESU_FIELDS.EMAIL)},
  form > div > div:nth-child(3) > div:first-child {
    max-width: unset;
  }

  button {
    width: 100%;
  }
`;

const CloseButton = styled(Icon)`
  position: absolute;
  width: 30px;
  height: 30px;
  right: ${spacing('md')};
  top: ${spacing('md')};
`;

const HeaderEmailSignUp = () => (
  <StaticQuery
    query={graphql`
      query HeaderESUQuery {
        contentfulEmailSignUp(name: { eq: "Header ESU" }) {
          title
          privacyCopy {
            raw
          }
          topCopy {
            raw
          }
          successCopy {
            raw
          }
          formColour
          buttonColour
          databaseTag
          campaignCode
        }
      }
    `}
    render={(headerESUData) => {
      const esu = get(headerESUData, 'contentfulEmailSignUp');
      const [showForm, setShowForm] = useState(false);
      const formWrapperRef = useRef(null);
      // Custom hook that stops blocks page scrolling when the model is open
      useDisableBodyScroll(showForm);
      // Custom hook that closes the modal on when the overlay is clicked
      useOnClickOutside(formWrapperRef, () => setShowForm(false));
      function handleEscClose(e) {
        // Close the modal if this is the ESC key
        if (e.keyCode === 27) {
          setShowForm(false);
        }
      }

      function handleOpenForm(e) {
        e.preventDefault();
        setShowForm(!showForm);
      }

      return esu !== null ? (
        <IconWrapper
          onKeyDown={e => handleEscClose(e)}
          data-test="header-esu"
        >
          <Icon
            onClick={e => handleOpenForm(e)}
            icon={HeaderIcons.email.icon}
            title={HeaderIcons.email.title}
            brand="comicrelief"
            target="self"
            role="button"
            href="#"
            tabIndex="0"
            isHeader
            id="esu"
          />
          {showForm && (
            <ModalWrapper>
              <FormWrapper
                ref={formWrapperRef}
                backgroundColour={snakeCase(esu.formColour)}
              >
                <EmailSignUp
                  title={esu.title}
                  topCopy={renderRichText(esu.topCopy, 'white', 'defaultPage')}
                  successCopy={renderRichText(
                    esu.successCopy,
                    'white',
                    'defaultPage'
                  )}
                  privacyCopy={renderRichText(
                    esu.privacyCopy,
                    'white',
                    'defaultPage'
                  )}
                  backgroundColour={snakeCase(esu.formColour)}
                  buttonColour={snakeCase(esu.buttonColour)}
                  databaseTag={esu.databaseTag}
                  campaignCode={esu.campaignCode}
                  columnLayout
                  isHeader
                />
                <CloseButton
                  onClick={e => handleOpenForm(e)}
                  icon={HeaderIcons.close.icon}
                  title={HeaderIcons.close.title}
                  brand={HeaderIcons.close.title}
                  target="self"
                  role="button"
                  href="#"
                  tabIndex="0"
                />
              </FormWrapper>
            </ModalWrapper>
          )}
        </IconWrapper>
      ) : null;
    }}
  />
);

export default HeaderEmailSignUp;
